.table {
  .radio,
  .checkbox {
    position: relative;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0px 0px;
    margin: 0px 5px;
    text-align: center;

    .icons {
      left: 5px;
    }
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 16px 8px;
    vertical-align: middle;
  }
  // MANU : aici este ceva schimbat!!!
  > tbody > tr > td {
    font-size: 14px;
    @media (max-width: 500px) {
      font-size: 13px;
      width: 120px;
      overflow: hidden;
    }
  }
  .statusWrapper {
    display: flex;
    align-items: center;
  }
  .active {
    background-color: #00ddb4;
    display: inline;
    color: white;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .unactive {
    background-color: #ff9933;
    color: white;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .expired {
    background-color: #4b4b4b;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .limited_open {
    background-color: #5c9ee0;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .schedule {
    background-color: #8577ff;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .sold {
    background-color: #e66464;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .closed {
    background-color: #4b4b4b;
    color: white;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .preview {
    background-color: #87cb16;
    color: white;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .close {
    background-color: #4b4b4b;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .draft {
    background-color: #b0b0b0;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
    font-weight: bold;
  }
  .cancel {
    background-color: #ff6363;
    display: inline;
    padding: 5px 8px;
    border: 0;
    border-radius: 6px;
  }
  > thead > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .td-actions .btn {
    @include opacity(0.36);

    &.btn-xs {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  .td-actions {
    min-width: 90px;
  }

  > tbody > tr {
    position: relative;

    &:hover {
      .td-actions .btn {
        @include opacity(1);
      }
    }
  }
}

.OrderTableWrapper {
  margin: 12px;
  .menuBox {
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 3px 0px 8px 0px;
    padding: 7px 0px;
    .menuContentBox {
      display: flex;
      .quantity {
        width: 10%;
        margin: 0px 7px;
        font-size: 13px;
        font-weight: bold;
      }
      .menuContent {
        width: 90%;
        margin: 0px 7px;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .optionItem {
      margin-left: 10px;
      width: 90%;
      font-size: 12px;
    }
  }

  .buttonWrapper {
    width: 20%;
    text-align: right;
    .statusButton {
      display: 'inline';
      position: relative;
      margin-left: 16px;
      width: 27px;
      height: 27px;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px;
      color: black;
      background-color: rgba(36, 77, 138, 0.1);
      cursor: pointer;
    }
  }

  .topContents {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .statusGroup {
      display: flex;
      font-size: 10px;
      height: 25px;
      width: 80%;
      font-weight: bold;
      color: white;
    }
  }

  .bodyContents {
    margin: 6px 0px;
    width: 100%;
    .boldContent {
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.tableData {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
