$sp: 480px; // スマホ
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.card {
  border-radius: $border-radius-base;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #ffffff;
  margin-bottom: 30px;

  .image {
    width: 100%;
    margin: 25px 0;
    overflow: hidden;
    max-height: 600px;
    border-radius: $border-radius-base $border-radius-base 0 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    img {
      width: 100%;
    }
  }
  .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    @include opacity(0);

    .btn {
      @include vertical-align();
    }
  }
  &:hover .filter {
    @include opacity(1);
  }
  .btn-hover {
    @include opacity(0);
  }
  &:hover .btn-hover {
    @include opacity(1);
  }
  .content {
    padding: 15px 15px 10px 15px;
  }
  .header {
    padding: 15px 15px 0;
  }
  .category {
    font-size: 12px;
    color: #787878;
    margin: 5px 0px 5px 0px;
  }
  label {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    color: #787878;
    margin-bottom: 0px;

    i {
      font-size: $font-paragraph;
    }
  }

  .checkBoxLabel {
    font-size: 12px;
    margin-bottom: 5px;
  }

  label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .title {
    margin: $none;
    color: $black-color;
    font-weight: $font-weight-bold;
  }
  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
  }
  .description {
    font-size: $font-size-base;
    color: #333;
  }
  .footer {
    padding: 0;
    margin-top: 4px;
    text-align: left;
    background-color: $transparent-bg;
    line-height: 30px;

    .legend {
      padding: 5px 0;
    }

    .footer-string {
      font-size: 10px;
      color: #787878;
      font-weight: bold;
      line-height: 12px;
    }

    hr {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .vertical-border {
      border-left: medium solid #ff00ff;
      height: 30px;
    }
  }
  .stats {
    color: #a9a9a9;
  }
  .footer div {
    display: inline-block;
  }

  .author {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
  .author i {
    font-size: $font-size-base;
  }
  h6 {
    font-size: $font-size-small;
    margin: 0;
  }
  &.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: $medium-gray;
    content: '';
    position: absolute;
  }

  .ct-chart {
    margin: 30px 0 30px;
    height: 245px;
  }

  .table {
    tbody td:first-child,
    thead th:first-child {
      padding-left: 15px;
    }

    tbody td:last-child,
    thead th:last-child {
      padding-right: 15px;
    }
  }

  .alert {
    border-radius: $border-radius-base;
    position: relative;

    &.alert-with-icon {
      padding-left: 65px;
    }
  }
  .content-text {
    font-size: $font-size-large;
    line-height: $font-size-large * 1.8;
  }
}
.card-user {
  background-color: #f6f6f7;

  .image {
    height: 60px;
    margin: 0px 0px 25px 0px;
  }
  .image-plain {
    height: 0;
    margin-top: 110px;
  }
  .author {
    text-align: center;
    text-transform: none;
    margin-top: -70px;
  }
  .avatar {
    width: 100%;
    height: 100%;
    max-width: 124px;
    max-height: 124px;
    // border: 5px solid #ffffff;
    position: relative;
    margin: 0 auto;
    margin-bottom: 15px;

    &.border-gray {
      border-color: #eeeeee;
    }

    i {
      align-self: center;
      font-size: 124px;
      color: 'black';
    }
  }
  .title {
    line-height: 24px;
  }
  .content {
    min-height: 160px;
  }
}

.card-user,
.card-price {
  .footer {
    padding: 5px 15px 10px;
  }
  hr {
    margin: 5px 15px;
  }
}
.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;

  .image {
    border-radius: 4px;
  }
}

.card-stats {
  .icon-big {
    font-size: 3em;
    min-height: 64px;
    i {
      font-weight: bold;
      line-height: 59px;
    }
  }
  .numbers {
    font-size: 2em;
    text-align: right;
    p {
      margin: 0;
    }
  }
}

.card-feature {
  margin-bottom: 15px;
  .flex-content {
    padding: 15px 15px 10px 15px;
    width: 75%;
    @media (max-width: 700px) {
      display: flex;
      align-items: center;
      width: 73%;
    }
  }
  .image {
    width: 50%;
    max-width: 200px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 100%;
    border-radius: $border-radius-base $border-radius-base 0 0;
    font-size: 80px;
    color: white;
    text-align: center;

    i {
      margin: 50px 0;
    }
  }

  .flex-icon {
    width: 20%;
    border-radius: $border-radius-base 0 0 $border-radius-base;
    font-size: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 700px) {
      width: 25%;
      font-size: calc(35px - 1vw);
    }

    i {
      margin: 20px 0;
    }
  }

  .title {
    margin: 0 0 10px 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
  }

  .flex-title {
    margin: 0 0 7px 0;
    font-weight: $font-weight-bold;
    font-size: 20px;
  }

  @media (max-width: 700px) {
    .flex-title {
      margin: 0px;
      font-weight: $font-weight-bold;
      font-size: 14px;
    }
  }

  .description {
    height: 90px;
  }

  .select-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
}

.mini-card {
  display: inline;
  border: 1.2px solid #c4c4c4;
  padding: 7px;
  border-radius: 5px;
  margin-right: 7px;
  img {
    width: 50px;
  }
}

.disabled-mini-card {
  display: inline;
  border: 1.2px solid #c4c4c4;
  padding: 7px;
  border-radius: 5px;
  margin-right: 7px;
  opacity: 0.6;
  background-color: rgba(196, 196, 196, 0.5);
  img {
    width: 50px;
  }
}
.fa-copy {
  margin-left: 7px;
}

.fa-copy:hover {
  color: #1dc7ea;
}

.branchNameWrapper {
  color: rgba(0, 0, 0, 0.68);
  background: rgb(230, 230, 230);
  font-weight: bold;
  border-radius: 5px;
  display: inline;
  margin: 10px 10px 10px 0px;
  padding: 5px;
  .closeBadge {
    min-width: 10px;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #888888;
    font-weight: bold;
    border-radius: 10px;
    margin-left: 7px;
    cursor: pointer;
  }
}

.cennectFormContainer {
  position: relative;
  height: 43px;
  margin: 0px 3px 0px 3px;
  .cennectForm {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.editStatus {
  position: relative;
  font-size: 18px;
  width: 28px;
  height: 27px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px;
  background-color: rgba(36, 77, 138, 0.1);
  cursor: pointer;
  color: black;
  border: 0;
}

.pe-7s-angle-up {
  margin-top: 1.5px;
  margin-left: 1.5px;
  font-size: 24px;
}

.tenantAlertContainer {
  border: 1px solid #8ddf8c;
  background-color: #e1f7e0;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  .tenatAlert {
    color: #01b902;
    display: flex;
  }
}

.separateDateOrders {
  color: #5c5c5c;
  font-weight: 600;
  font-size: 14px;
  margin: 3px;
  cursor: pointer;
}

.selectSeparateDateOrders {
  color: #1dc7ea;
  font-size: 14px;
  margin: 3px;
  cursor: pointer;
}

.unactiveSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #999999;
  padding-right: 2px;
}

.activeSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding-right: 2px;
}

.divideItem {
  height: 1px;
  margin: 3px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.cardStatus {
  font-weight: 600;
  color: #333333;
  margin-left: 30px;
}

.ticketSection {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.unitText {
  margin-right: 5px;
  margin-left: 5px;
  line-height: 38px;
}

.flexForm {
  display: flex;
  padding-left: 15px;
}

.flexCheckBox {
  display: flex;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  align-items: center;
}

.flexForm {
  display: flex;
  padding-left: 15px;
  margin-bottom: 10px;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.isActiveTd {
  font-weight: bold;
  color: white;
}

.rightHeader {
  display: inline-block;
  float: right;
}
