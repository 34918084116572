@mixin pill-style($color) {
  border: 1px solid $color;
  color: $color;
}

.swipeTabs {
  .nav-tabs {
    display: flex;
    white-space: nowrap;
    overflow: scroll hidden;
  }
}
